.gallery {
  padding: 2rem 3rem;
  max-width: 1400px;
  margin: 0 auto;
}

.gallery .videos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.gallery .loading {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 200px;
  margin: 2rem 0;
  background: rgba(248, 249, 250, 0.8);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.gallery .gallery-title {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 2rem;
  font-weight: 600;
}

.controls-container {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.filters, .sort, .query {
  margin: 1rem 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e9ecef;
}

.controls-label {
  font-weight: 500;
  color: #495057;
  margin-right: 1rem;
  min-width: 80px;
  display: inline-block;
}

.btn-filter-no-tags,
.btn-filter-tag,
.btn-sort {
  border: 1px solid #dee2e6;
  background: white;
  padding: 0.5rem 1rem;
  color: #495057;
  border-radius: 6px;
  margin: 0.25rem;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

.btn-filter-no-tags:hover,
.btn-filter-tag:hover,
.btn-sort:hover {
  background: #e9ecef;
  color: #212529;
  cursor: pointer;
}

.btn-filter-no-tags.active,
.btn-filter-tag.active {
  background: #0d6efd;
  color: white;
  border-color: #0d6efd;
}

.btn-sort.active {
  background: #fd7e14;
  color: white;
  border-color: #fd7e14;
}

.query input {
  width: 400px;
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  margin-right: 0.5rem;
  transition: border-color 0.2s ease;
}

.query input:focus {
  outline: none;
  border-color: #0d6efd;
  box-shadow: 0 0 0 2px rgba(13,110,253,0.25);
}

.query .btn-query {
  padding: 0.5rem 1.5rem;
  background: #0d6efd;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.query .btn-query:hover {
  background: #0b5ed7;
}

.query .query-help {
  margin: 0.75rem 0;
  color: #6c757d;
  font-size: 0.875rem;
  line-height: 1.4;
}

.pagination {
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.pagination select {
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  background: white;
  cursor: pointer;
}

.pagination .pagination-btn {
  padding: 0.5rem 1rem;
  background: #0d6efd;
  border: none;
  border-radius: 6px;
  color: white;
  transition: background-color 0.2s ease;
}

.pagination .pagination-btn:hover:not(:disabled) {
  background: #0b5ed7;
  cursor: pointer;
}

.pagination .pagination-btn:disabled {
  background: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
}

.page-num {
  color: #495057;
  font-weight: 500;
}
