.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-height: 200px;
}

.loader-container.fullscreen {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1050;
  background-color: rgba(255, 255, 255, 0.9);
}

.loader-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  z-index: 2;
}

.loader-pulse {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 123, 255, 0.1);
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.6;
  }
}
