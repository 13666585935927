.video {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 16px 0;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: visible;
  position: relative;
  width: 300px;
}

.video:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.video img {
  width: 300px;
  height: 169px;
  object-fit: cover;
  padding: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: block;
  border-radius: 8px 8px 0 0;
  position: relative;
}

.video img:hover {
  transform: scale(1.02);
}

.video-info {
  padding: 16px;
  position: relative;
  background: #ffffff;
}

.video-info .video-name,
.video-info .video-title,
.video-info .video-author {
  margin-bottom: 8px;
  font-size: 0.95rem;
  line-height: 1.4;
  color: #2c3e50;
}

.video-info .label {
  font-weight: 600;
  color: #34495e;
  margin-right: 4px;
}

.video-info.inactive {
  color: #e74c3c;
  text-decoration: line-through;
  opacity: 0.7;
}

.two-col-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  padding: 12px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.video-duration {
  font-size: 0.9rem;
  color: #666;
}

.video-rating {
  display: flex;
  gap: 16px;
}

.video-selected,
.video-favorite,
.video-dislike {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9rem;
  color: #666;
}

.video-rating .material-icons {
  font-size: 18px;
}

.video-selected .material-icons {
  color: #3498db;
}

.video-favorite .material-icons {
  color: #e74c3c;
}

.video-dislike .material-icons {
  color: #95a5a6;
}

.video-tags {
  margin-top: 16px;
  position: relative;
}

/* Multiselect customization */
.video-tags .multiSelectContainer {
  width: 100% !important;
}

.video-tags .searchWrapper {
  border: 1px solid #e0e0e0 !important;
  border-radius: 6px !important;
  padding: 8px !important;
  margin-top: 8px !important;
  background: white !important;
  position: relative !important;
}

.video-tags .optionContainer {
  position: absolute !important;
  width: 100% !important;
  background: white !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 6px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  max-height: 300px !important;
  overflow-y: auto !important;
}

.video-tags .chip {
  background: #52a7e0 !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  margin: 2px !important;
  color: white !important;
  font-size: 0.85rem !important;
  position: relative !important;
}

.video-tags .chip img {
  width: 14px !important;
  height: 14px !important;
  margin-left: 6px !important;
  cursor: pointer !important;
}

.video-tags .optionContainer li {
  padding: 8px 12px !important;
  font-size: 0.9rem !important;
  background: white !important;
}

.video-tags .optionContainer li:hover {
  background: #52a7e0 !important;
  color: white !important;
}

.btn-save {
  margin-top: 16px;
  text-align: right;
  position: relative;
}

.btn-save button {
  background: #2ecc71;
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn-save button:hover {
  background: #27ae60;
}
