@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap");

.stats {
  padding: 2rem;
}

.stats .stats-title {
  text-align: center;
  width: 100%;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.stats .items {
  border-radius: 12px;
  padding: 2rem;
  font-family: "Roboto Mono", monospace;
  width: 500px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin: auto;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  box-shadow: 0 8px 30px rgba(0,0,0,0.12);
}

.stats .items .item {
  background: white;
  padding: 1.2rem;
  border-radius: 8px;
  transition: transform 0.2s;
  box-shadow: 0 2px 10px rgba(0,0,0,0.06);
}

.stats .items .item:hover {
  transform: translateY(-2px);
}

.item-label {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.item-value {
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 500;
}

.items-footer {
  grid-column: 1 / -1;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.8rem;
  color: #666;
}

.items-refresh {
  grid-column: 1 / -1;
  text-align: center;
  margin-top: 1rem;
}

.refresh-btn {
  font-family: "Roboto Mono", monospace;
  padding: 0.8rem 2rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.95rem;
  font-weight: 500;
  box-shadow: 0 2px 10px rgba(52, 152, 219, 0.2);
}

.refresh-btn:hover:not(:disabled) {
  background: #2980b9;
  transform: translateY(-1px);
  box-shadow: 0 4px 15px rgba(52, 152, 219, 0.3);
}

.refresh-btn:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.refresh-btn .loader-wrapper {
  display: inline-flex;
  align-items: center;
}

.loading-spinner {
  width: 500px;
  margin: auto;
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0,0,0,0.12);
}

.loading-spinner .spinner-border {
  width: 3rem;
  height: 3rem;
}
