.modal-container {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  backdrop-filter: blur(5px);
}

.video-modal {
  background: linear-gradient(to bottom, #1a1a1a, #000);
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  width: 800px;
  height: 700px;
  animation: modalopen 0.3s ease-out;
}

.modal-header {
  background: linear-gradient(to right, rgb(60, 61, 119), rgb(45, 46, 90));
  color: #fff;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-header h3 {
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.modal-content {
  position: relative;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

.modal-video-container {
  position: relative;
  width: 100%;
  flex: 1;
  background: #000;
}

.modal-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.close-btn {
  background: transparent;
  font-size: 24px;
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  outline: none;
  border: none;
  padding: 5px;
  margin: 0;
  transition: color 0.2s ease;
  z-index: 2;
}

.close-btn:hover {
  color: #fff;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.loading-container.fade-out {
  opacity: 0;
}

.loading-container p {
  color: #fff;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  animation: pulse 2s ease-in-out infinite;
}

.video-details {
  background: linear-gradient(to bottom, #2a2a2a, #1a1a1a);
  padding: 15px 20px;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.video-stats {
  display: flex;
  gap: 20px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.stat-item .material-icons {
  font-size: 18px;
  color: #3498db;
}

.video-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.tag {
  background: rgba(52, 152, 219, 0.2);
  border: 1px solid rgba(52, 152, 219, 0.3);
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  color: #3498db;
}

.modal-vid-info {
  height: 70px;
  background: linear-gradient(to bottom, #2a2a2a, #000000);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 0;
}

.modal-vid-info .vid-info-active {
  padding: 5px;
}

.modal-vid-info .vid-info-save {
  padding: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.vid-info-save .save-btn {
  padding: 12px 24px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 220px;
}

.vid-info-save .save-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.save-btn.btn-danger {
  background: #dc3545;
  border-color: #dc3545;
}

.save-btn.btn-danger:hover {
  background: #c82333;
  border-color: #bd2130;
}

.modal-vid-info .vid-info-label {
  margin-right: 5px;
}

@keyframes modalopen {
  from {
    opacity: 0;
    transform: translate(-50%, -48%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes pulse {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 1; }
}
