.summary {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.summary h1 {
  color: #2c3e50;
  margin-bottom: 2rem;
  font-weight: 600;
}

.summary h3 {
  color: #34495e;
  margin-bottom: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: color 0.2s ease;
}

.summary h3:hover {
  color: #2c3e50;
}

.summary-section {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.table-responsive {
  overflow-x: auto;
  margin-bottom: 1rem;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.summary table {
  margin-bottom: 0;
  background: #fff;
}

.summary table thead th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  color: #495057;
  font-weight: 600;
  padding: 1rem;
  user-select: none;
}

.summary table thead th:hover {
  background-color: #e9ecef;
}

.summary table tbody td {
  padding: 1rem;
  vertical-align: middle;
  color: #2d3436;
}

.summary table tbody tr:hover {
  background-color: #f8f9fa;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.plan-summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.stat-card {
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.stat-card h4 {
  color: #6c757d;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.stat-card .stat-value {
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 600;
}

.empty-state {
  text-align: center;
  padding: 2rem;
  color: #6c757d;
  background: #f8f9fa;
  border-radius: 8px;
}

.empty-state p {
  margin-bottom: 0;
  font-size: 0.9rem;
}

.table-controls {
  margin-bottom: 1.5rem;
}

.table-controls .input-group {
  max-width: 500px;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.pagination-info {
  color: #6c757d;
}

.pagination-buttons {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.pagination-buttons .btn {
  min-width: 40px;
  padding: 0.375rem 0.75rem;
}

.collapsible-header {
  cursor: pointer;
  padding: 0.5rem;
  margin: -0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.collapsible-header:hover {
  background-color: #f8f9fa;
}

@media (max-width: 768px) {
  .summary {
    padding: 1rem;
  }
  
  .summary-section {
    padding: 1rem;
  }
  
  .summary table thead th,
  .summary table tbody td {
    padding: 0.75rem;
  }

  .pagination-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .pagination-info {
    text-align: center;
  }

  .pagination-buttons {
    justify-content: center;
  }
}

.loading-skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 4px;
  height: 20px;
  margin: 10px 0;
}

@keyframes skeleton-loading {
  0% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
