.login-card {
  max-width: 450px;
  margin: 2rem auto;
  background: #ffffff;
  border-radius: 15px !important;
  transition: all 0.3s ease;
}

.login-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
}

.form-control {
  padding: 0.75rem 1.25rem;
  border: 1px solid #e1e1e1;
  transition: all 0.3s ease;
}

.form-control:focus {
  border-color: #446e9b;
  box-shadow: 0 0 0 0.2rem rgba(68, 110, 155, 0.15);
}

.btn-primary {
  padding: 0.75rem 2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-1px);
  box-shadow: 0 5px 15px rgba(68, 110, 155, 0.3);
}

.btn-link {
  font-weight: 500;
  color: #446e9b;
}

.btn-link:hover {
  color: #345578;
}

.form-label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #495057;
}

.invalid-feedback {
  font-size: 0.85rem;
  margin-top: 0.25rem;
  margin-left: 0.75rem;
}

@media (max-width: 576px) {
  .login-card {
    margin: 1rem;
  }
}
