main {
  min-height: 85vh;
}

/* Text colors */
/* .text-primary {
  color: #28a745;
} */

/* .text-secondary {
  color: #0284d0;
} */

/* Reset */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.7rem;
  color: #333;
  background: #fff;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

h1,
h2,
h3 {
  font-weight: 300;
  line-height: 1.2rem;
  padding: 20px 0;
}

p {
  margin: 10px 0;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.loading {
  margin-left: 20px;
} */

/* Navbar  */
/* #navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #333;
  color: #fff;
  overflow: auto;
  width: 100%;
  height: 70px; */
/* position: fixed;
  top: 0px; */
/* padding: 0 30px; */
/* } */

/* .navbar a {
  color: #fff;
  padding: 10px 20px;
  margin: 0 5px;
}

.navbar a:hover {
  border-bottom: #28a745 2px solid;
}

.navbar ul {
  display: flex;
}

.navbar .logo {
  font-weight: 400;
} */
